// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-dreamline-worksheet-js": () => import("./../../../src/pages/dreamline-worksheet.js" /* webpackChunkName: "component---src-pages-dreamline-worksheet-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-monthly-expense-calculator-js": () => import("./../../../src/pages/monthly-expense-calculator.js" /* webpackChunkName: "component---src-pages-monthly-expense-calculator-js" */),
  "component---src-pages-tags-js": () => import("./../../../src/pages/tags.js" /* webpackChunkName: "component---src-pages-tags-js" */),
  "component---src-pages-tech-stack-js": () => import("./../../../src/pages/tech-stack.js" /* webpackChunkName: "component---src-pages-tech-stack-js" */),
  "component---src-pages-what-i-am-reading-js": () => import("./../../../src/pages/what-i-am-reading.js" /* webpackChunkName: "component---src-pages-what-i-am-reading-js" */),
  "component---src-pages-work-js": () => import("./../../../src/pages/work.js" /* webpackChunkName: "component---src-pages-work-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-tags-js": () => import("./../../../src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */)
}

